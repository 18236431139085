<template>
  <div class="container" ref="container">
    <!-- 法律声明 -->
    <!-- <div class="left">
      <div class="leftOne">法律声明</div>
      <div class="leftOne">隐私声明</div>
      <div class="leftOne">使用条款</div>
    </div> -->
    <ul class="sidebar">
      <li :class="item.title == selectName ? 'side-item active' : 'side-item'" v-for="(item, index) in list" :key="index"
        @click="select(item)">
        <span>{{ item.title }}</span>
      </li>
    </ul>
    <div class="right" v-if="selectName == '法律声明'">
      <h4 class="tip">法律声明</h4>
      <div class="inner">
        <section class="law-inner" style="">
          <p class="divider">
            如您使用京科仓链（以下称“本网站”)，即已同意下列条款，如我方对条款内容作出变更，将以公告的形式在首页显著位置公开征求您的意见，在实施前七日将在首页予以公示，以确保您能够及时知悉并充分表达意见。如您不同意条款变更，可向平台提出修改建议；如您继续使用则视为您同意适用修改后条款。
          </p>
          <p class="divider">
            1、
            “京科仓链”为中储京科供应链管理有限公司及其关联公司的商标，受法律保护，任何人不得擅自使用。凡侵犯本公司版权等知识产权的，中储京科供应链管理有限公司将依法追究其相关法律责任。
          </p>
          <p class="divider">
            2、转载的或者任何信息发布人通过本网站发布的信息或内容，并不代表本网站之意见及观点，也不意味着本网站赞同其观点或证实其内容的真实性。本网站所包含的资料或信息不视为任何形式的要约、要约邀请或向浏览者提出的任何建议。
          </p>
          <p class="divider">
            3、本网站转载的或者任何通过本网站发布的信息、文字、图片、音视频等资料均由本网站用户提供，其真实性、准确性和合法性由信息提供人负责。本网站不提供任何保证，并不承担任何法律责任。
          </p>
          <p class="divider">
            4、本网站所转载的或者任何通过本网站发布的信息、文字、图片、音视频等资料，如果侵犯了第三方的知识产权或其他权利，责任由信息提供者或转载的用户承担，本网站站对此不承担责任。
          </p>
          <p class="divider">
            5、对本网站拥有版权的所有内容，经本网站书面许可使用的单位或个人，在使用时必须注明“稿件来源：京科仓链”，并标明本网站址cw.cmstjd.com，违者本网站将依法追究其相关法律责任。
          </p>
          <p class="divider">
            6、京科仓链在线下宣传的材料及线上展示的材料以及其他互联网网络平台引用发布京科仓链所展示的资料均由本网站的用户提供，其真实性、准确性、合法性由资料提供人负责。本网站不提供任何保证亦不承担任何法律责任。
          </p>
          <p class="divider">
            7、本网站信息展示内容中涉及的专利、商标等知识产权内容，均由本网站的用户提供，其真实性、准确性、合法性由资料提供人负责。本网站不提供任何保证亦不承担
            任何法律责任。
          </p>
          <p class="divider">
            8、转载或引用本网站内容必须是以新闻性或资料性公共免费信息服务为目的的合理、善意使用，不得对本网站内容原意进行曲解、修改，同时必须保留本网站注明的“稿件来源”，并自负版权侵权等法律责任。
          </p>
          <p class="divider">
            9、凡本网站注明“来源：XXX
            ”的文/图等资料，本网站转载出于传递更多信息之目的，并不意味着赞同其观点或证实其内容的真实性
          </p>
          <p class="divider">
            10、转载或引用本网站其他版权的所有内容须注明“转自（或引自）京科仓链”字样，并标明本网站网址cw.cmstjd.com。转载或引用本网站中的署名文章，请按规定向作者支付稿酬。
          </p>
          <div class="divider">
            11、除注明“来源：京科仓链”的内容外，本网站以下内容亦不可任意转载：
            <div class="divider">
              <p>A、本网站所指向的非本网站内容的相关链接内容；</p>
              <p>B、已作出不得转载或未经许可不得转载声明的内容；</p>
              <p>
                C、未由本网站署名或本网站引用、转载的他人作品等非本网站版权内容；
              </p>
              <p>D、本网站中特有的图形、标志、页面风格、编排方式、程序等；</p>
              <p>
                E、本网站中必须具有特别授权或具有注册用户资格方可知晓的内容；
              </p>
              <p>F、其他法律不允许或本网站认为不适合转载的内容。</p>
            </div>
          </div>
          <div class="divider">
            12、转载或引用本网站内容不得进行如下活动：
            <div class="divider">
              <p>A、损害本网站、他人或其他社会群体利益的行为；</p>
              <p>B、任何违反法律法规的行为。</p>
            </div>
          </div>
          <p class="divider">
            13、对于不当转载或引用本网站内容而引起的纷争等或因纠纷等造成的任何损失，本网站不承担相关法律责任。
          </p>
          <p class="divider">
            14、对不遵守本声明的用户或其他违法、恶意使用本网站内容者，本网站保留追究其法律责任的权利。
          </p>
          <p class="divider">
            15、本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。
          </p>
          <p class="divider">
            16、本网站相关声明版权及其修改权、更新权和解释权均属中储京科供应链管理有限公司所有。
          </p>
          <p class="divider">
            17、凡以任何方式登陆京科仓链或直接、间接使用京科仓链资料者，视为自愿接受本声明的约束。
          </p>
        </section>
        <section class="privacy-inner" style="display: none">
          <div>
            <h4 class="txt-title">(一) 关于我们</h4>
            <div class="divider">
              本隐私说明（“本说明”）中的“我们”、“中储京科”系指中储京科供应链管理有限公司及其在收集、使用、共享或以其他方式处理浏览访问信息中涉及的公司。
              <p class="divider">
                本政策中的“我们的网站”系指中储京科网站<a href="https://cw.cmstjd.com/" target="_blank">www.cmstjd.com</a>
                （二级域名cw.cmstjd.com）
              </p>
            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">(二) 价值和本说明的原则</h4>
            <p class="divider">
              我们非常重视您的隐私。负责、公平和透明是我们收集和使用您的浏览访问信息所遵循的原则。本说明也旨在让您了解您对浏览访问信息享有的权利和选择。
            </p>
            <p class="divider">
              为实现上述价值，本说明解释了我们的浏览访问信息收集和使用目的、方式和范围，并尽量以更清晰、更容易被您查询的方式展现与您最相关的信息和您与我们的关系。
            </p>
            <p class="divider">
              我如果您对本说明中我们对您提供展示的信息和联系方式有任何疑问或修改意见，请通过以下方式与我们联系：
            </p>
            <div class="divider">
              <p style="text-indent: 24px">电话：010-83673286/17600335887</p>
              <p style="text-indent: 24px">邮箱：lijiabin@huoduibao.com</p>
            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">(三) 范围</h4>
            <div class="divider">
              本说明适用于我们在您进行以下活动时收集的浏览访问信息的处理：
              <p class="indent">(1) 访问显示有本说明的我们的网站；</p>
              <p class="indent">(2) 访问我们的社交媒体页面；</p>
              <p class="indent">
                (3) 接收我们的通信，包括电子邮件、电话、短信或传真；
              </p>
              <p class="indent">
                (4)
                使用我们的在线服务或以任何电子数据与我们沟通业务，包括我们潜在或现有的投资人、客户及我们的供应商或第三方供应商；
              </p>
            </div>
            <div class="divider">
              根据我们与您之间不同的服务内容，我们收集和使用您个人信息的方式是不同的。请知悉您接受我们提供的服务，视为同意与当下收集渠道相关的您浏览访问信息的收集和使用。
            </div>
            <div class="divider">
              若某些特定服务有单独的隐私说明，则该单独隐私说明将被优先适用。该特定服务的单独隐私说明未涵盖的部分，以本政策内容为准。
            </div>
          </div>
        </section>
        <section class="usage-inner" style="display: none">
          <div>
            在您使用本网站之前，请仔细阅读本使用条款的所有条款。您一旦链接、使用本网站，即表明您无条件地接受本使用条款，您应遵守本使用条款和相关法律的规定。
          </div>
          <div class="divider">
            本网站的版权归中储京科供应链管理有限公司所有，其保护对象包括但不限于本网站的文本、图形、图像、LOGO标志、创意及软件著作权等。
          </div>
          <div class="divider">
            <h4 class="txt-title">内容</h4>
            <div class="divider">
              本网站包含与中储京科供应链管理有限公司及本网站展示客户有关的信息仅用做信息展示的目的。
            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">解释权</h4>
            <div class="divider">
              以上使用条款内容的解释权归中储京科供应链管理有限公司所有。
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="right" v-if="selectName == '隐私声明'">
      <h4 class="tip">隐私声明</h4>
      <div class="inner">

        <section class="privacy-inner" style="">
          <div>
            <p>隐私权是用户的重要权利，对于京科仓链的所有用户，我们均会以负责的态度对待您的个人信息，特此制定京科仓链（https://cw.cmstjd.com/，以下称"本网站"）对用户相关信息所采取的收集、使用和保护政策。 京科仓链隐私保护声明系本网站保护用户个人隐私的公告和承诺，请您务必仔细阅读。</p>
            <h4 class="txt-title">一、用户非个人信息</h4>
            <div class="divider">
              我们将通过您的IP地址来收集非个人化的信息，例如用户的浏览器性质、操作系统种类、给您提供接入服务的ISP域名、您访问本网的顺序和链接途径、您访问时所在的地理位置等。通过收集上述信息，我们将优化在您计算机屏幕上显示的页面，同时进行客流量统计、访问者阅读习惯整理等，从而改进本网站的管理和服务。这些无关个人身份的信息能帮助我们识别本网站最受欢迎的地区和内容等，并帮助我们确定推广活动的有效性。此外，我们也可能将这些信息披露给我们的广告客户，使他们知道点击他们广告的效果数据。

            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">二、用户企业及个人信息</h4>
            <p class="divider">
              1、当您在本网站进行用户注册登记、参加网上论坛等各种活动时，在您的同意并确认下，本网站将通过注册表格等形式要求您提供一些企业及个人信息。这些信息包括：企业名称、营业执照、法人姓名、联系方式等信息。
            </p>
            <div class="divider">
              2、请了解，在未经您同意并确认之前，本网站不会将您提供的信息用于其它目的。惟按下列第八章规定应政府及法律相关要求披露时不在此限。
              本网站的目标是向所有用户提供愉快、有益的上网经历，并使每个用户使用本网站相关服务时能够更容易、更满意，而收集这些个人信息有助于我们实现这一目标。 所有的注册成员和访问者都应该知道，当他们在公告栏、聊天室或其他网上公开场合披露其个人信息时，该信息可能会被他人收集并使用，包括用来向该用户发送未经邀约的电子邮件等广告信息。
            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">三、用户权利</h4>
            <div class="divider">
              1、本网站用户对于自己的个人信息享有以下权利：
              <p class="indent">（1）随时查询及请求阅览；</p>
              <p class="indent">（2）随时请求补充或更正；</p>
              <p class="indent">（3）随时请求删除；</p>
              <p class="indent">（4）请求停止电脑处理及使用。</p>

            </div>
            <div class="divider">
              2、本网站为用户提供各种配套服务，此类信息也将成为本网用户数据的一部分，并将被后续记录、保存、保护和合理使用。是否提供所要求的信息和是否参加此类活动，完全取决于用户自身。
            </div>
            <div class="divider">
              3、针对以上权利，本网站为您提供相关服务，如用户有任何建议或意见，请发送电子邮件至 lijiabin@huoduibao.com。
            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">四、用户信息安全保护</h4>
            <div class="divider">
              1、本网站将严格保护您的个人信息安全，对您所提供的信息采取严密的管理及保护措施。我们将采用相应的安全技术和程序来防止您的个人信息丢失、被盗用或遭篡改，并且保护您的个人信息不被未经授权者访问、使用或泄露。

            </div>
            <div class="divider">
              2、本网站将在必要时委托专业技术人员代为对该类资料进行电脑处理，以符合专业分工之需求。如本网站将电脑处理之通知送达予您，而您未在通知规定的时间内主动明示反对，本网站将善意推定您已同意。此后您仍然有权按照上述第三章第1条第（4）款之规定，请求停止电脑处理。
            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">五、用户隐私自我保护</h4>
            <div class="divider">
              1、用户是唯一对自己的账号密码及其相关注册信息负有权限及保密责任的人。因此，当你在线时，请务必小心保护自己的账户安全。
            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">六、未成年人隐私权的保护</h4>
            <div class="divider">
              1、本网站将建立相应合理程序，以保护未成年人个人信息的安全。本网站郑重声明：任何十六岁以下的未成年人参加网上活动应事先得到家长或其法定监护人（以下统称为“监护人”）的明确同意。


            </div>
            <div class="divider">
              2、未经监护人同意，未成年人私自上网或者冒用成年人名义上网的行为将可能缺乏与未成年人相应的信息安全保护措施。
            </div>
            <div class="divider">
              3、监护人应承担保护未成年人相关网络隐私的首要责任。
            </div>
            <div class="divider">
              4、本网站收集未成年人的个人信息，仅为更好服务于未成年人特定要求的目的，相关资料仅作为保护未成年人参与网络活动时的安全之用，而不会保留这些信息做进一步或其他商业目的的使用。
            </div>
            <div class="divider">
              5、未经监护人同意，本网站将不会使用未成年人的个人信息，亦不会向任何第三方披露或传送可识别该未成年人的个人信息。惟按下列第八章规定应政府及法律相关要求披露时不在此限。
            </div>
            <div class="divider">
              6、若经未成年人的监护人同意，本网站可对未成年人的个人信息进行收集，本网站将向监护人提供：
              <p class="indent">（1）审视其子女或被监护人相关信息的机会；</p>
              <p class="indent">（2）拒绝其子女或被监护人相关个人信息被进一步收集或使用的可能；</p>
              <p class="indent">（3）变更或删除其子女或被监护人个人信息。</p>
            </div>
            <div class="divider">
              7、监护人有权拒绝本网站与其子女或被监护人做进一步的联络。
            </div>
          </div>
          <div class="divider">
            <h4 class="txt-title">七、用户信息使用原则 惟在符合下列条件之一的情况下，本网站方可能对本网用户的个人信息进行必要范围之外的合理使用：</h4>
            <div class="divider">
              1、已取得您的书面同意；

            </div>
            <div class="divider">
              2、为免除您在生命、身体或财产方面的紧急危险；

            </div>
            <div class="divider">
              3、为防止他人权益的重大危害；

            </div>
            <div class="divider">
              4、为增进公共利益，且无害于您的重大利益。

            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">八、用户信息披露原则</h4>
            <div class="divider">
              1、根据中华人民共和国相关法律法规，当相关政府机关依照法定程序要求本网站披露相关个人信息时，本网站将根据执法单位之要求或为公共安全之目的提供相关资料。在此情况下对用户信息的任何披露，本网站均不承担任何责任。
            </div>
            <div class="divider">
              2、其他本网站因善意确信或合理原因有必要对相关用户信息进行披露的情形：
              <p class="indent">（1）根据本网站服务协议或本网站其他相关声明所列的条款，有必要进行相关信息公开的；</p>
              <p class="indent">（2）在紧急的情况下，为保护京科仓链控股公司及京科仓链用户的人身或财产安全的；</p>
              <p class="indent">（3）为体现公平公正原则，需要对用户的部分信息进行公示的。</p>

            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">九、免责</h4>
            <div class="divider">
              除上述第八章规定的免责情形外，下列情况本网站亦毋需承担任何责任：
              <p class="indent">1、由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息泄露，或其他非因本网站原因导致的个人信息泄露；</p>
              <p class="indent">2、任何由于黑客政击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之非京科仓链原因而造成的个人信息泄露、丢失、被盗用或被篡改等；</p>
              <p class="indent">3、任何第三方根据本网站各服务声明及服务协议中所列明的情况使用您的个人信息，由此所产生的纠纷；</p>
              <p class="indent">4、本网站在各服务声明及服务协议中列明的所有免责情形；</p>
              <p class="indent">
                5、本网站的网页上有与其他网站网页的链接。本网站对其他任何网站的内容、隐私政策、运营情况、或经营这些网站的公司的行为概不负责。在向这些与本网站链接的网站提供个人信息之前，请查阅它们的隐私政策。由于与本网站链接的其它网站所造成的个人信息泄露及由此而导致的任何法律争议和后果，本网站概不负责；
              </p>
              <p class="indent">6、任何因不可抗力所导致的后果。</p>

            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">十、相关法律</h4>
            <div class="divider">
              本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。


            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">十一、本网站相关声明版权及其修改权、更新权和最终解释权均属京科仓链所有。</h4>


          </div>
        </section>

      </div>
    </div>
    <div class="right" v-if="selectName == '使用条款'">
      <h4 class="tip">京科仓链用户使用协议</h4>
      <div class="inner">


        <section class="usage-inner" style="">
          <div style="color: #666;">
            尊敬的客户，欢迎您注册成为京科仓链（以下简称：本网站）用户。在注册前请您仔细阅读如下服务条款：
            本服务协议双方为本网站与本网站客户，本服务协议具有合同效力。您确认本服务协议后，本服务协议即在您和本网站之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。
            无论您事实上是否在注册之前认真阅读了本服务协议，只要您点击协议正本下方的"登录"按钮并按照本网站注册程序成功注册为用户，您的行为仍然表示您同意并签署了本服务协议。
          </div>
          <div class="divider">
            本网站的版权归中储京科供应链管理有限公司所有，其保护对象包括但不限于本网站的文本、图形、图像、LOGO标志、创意及软件著作权等。
          </div>
          <div class="divider">
            <h4 class="txt-title">八、用户信息披露原则</h4>
            <div class="divider">
              1、根据中华人民共和国相关法律法规，当相关政府机关依照法定程序要求本网站披露相关个人信息时，本网站将根据执法单位之要求或为公共安全之目的提供相关资料。在此情况下对用户信息的任何披露，本网站均不承担任何责任。
            </div>
            <div class="divider">
              2、其他本网站因善意确信或合理原因有必要对相关用户信息进行披露的情形：
              <p class="indent">（1）根据本网站服务协议或本网站其他相关声明所列的条款，有必要进行相关信息公开的；</p>
              <p class="indent">（2）在紧急的情况下，为保护京科仓链控股公司及京科仓链用户的人身或财产安全的；</p>
              <p class="indent">（3）为体现公平公正原则，需要对用户的部分信息进行公示的。</p>

            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">1、本网站服务条款的确认和接纳</h4>
            <div class="divider">
              本网站各项服务的所有权和运作权归本网站拥有。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">2、注册义务：</h4>

            <div class="divider">
              本网站运用自己的操作系统为用户提供网络服务，同时用户必须：
              <p class="indent">（1）自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置。</p>
              <p class="indent">（2）自行负担个人上网所支付的与此服务有关的电话费用、网络费用。</p>
              <p class="indent">（3）本网站为用户提供服务，用户在使用本网站提供的服务时需提供真实身份信息，并对自己在本网站的行为言论负责。</p>

            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">3、用户在本网站交易平台上不得发布下列违法信息：</h4>

            <div class="divider">

              <p class="indent">（1）反对宪法所确定的基本原则的；</p>
              <p class="indent">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
              <p class="indent">（3）损害国家荣誉和利益的；</p>
              <p class="indent">（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
              <p class="indent">（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
              <p class="indent">（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>

              <p class="indent">（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>

              <p class="indent">（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>

              <p class="indent">（9）含有法律、行政法规禁止的其他内容的。</p>


            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">4、有关个人资料</h4>

            <div class="divider">
              用户必须同意：
              <p class="indent">（1）依本服务注册表的提示提供您本人真实、正确、最新及完整的资料。</p>
              <p class="indent">
                （2）维持更新您个人用户信息，确保其真实、正确、最新及完整。若您提供任何错误、不实、过时或不完整的资料，并为本网站所确知，或者本网站有合理的理由怀疑前述资料为错误、不实、过时或不完整，本网站有权暂停或终止您的帐号，并拒绝您于现在和未来使用本网站全部或部分的服务。
              </p>
              <p class="indent">（3）您提供的注册信息不得冒用他人信息或侵犯他人合法权益。发生前述情形的，本网站有权暂停或终止帐号，并有权追究相关责任人的法律责任。</p>
              <p class="indent">（4）您注册的用户名不能侵犯他人合法权益，不能对他人名誉权造成侵犯。本网站有权收回对本网站或者他人合法权益有侵犯嫌疑的帐号。</p>

            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">5、服务条款的修改</h4>
            <div class="divider">
              本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保留随时修改或中断服务而不需通知用户的权利。本网站行使修改或中断服务的权利，不需对用户或第三方负责。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">6、用户的帐号、密码和安全性</h4>
            <div class="divider">
              一旦注册成功成为用户，用户将得到一个密码和帐号。同时此协议即时生效，用户有义务保证密码和账号的安全并对利用该密码和账号所进行的一切活动负全部责任。用户可随时根据指示更改密码，也可以结束旧的帐户重开一个新帐户。用户若发现任何非法使用用户帐号或安全漏洞的情况，请立即通知本网站。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">7、拒绝提供担保</h4>
            <div class="divider">
              用户明确同意信息服务的使用风险由用户个人承担。本网站不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保 ，但会在能力范围内，避免出错。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">8、有限责任</h4>
            <div class="divider">
              本网站对任何因用户不正当使用本网站服务，或用户传送的信息不符合规定等行为导致的直接、间接、偶然、特殊及继起的损害不负责任。这些行为都有可能导致本网站形象受损，所以本网站事先提出这种损害的可能性，同时会尽量避免这种损害的发生。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">9、信息的储存及限制</h4>
            <div class="divider">
              本网站不对用户所发布信息的删除或储存失败负责。本网站并不承诺对用户的存储信息进行无限期保留。除非本协议中另有规定，否则本网站不保证服务一定会满足用户的使用要求，也不保证服务不会受中断，对服务的及时性、安全性、准确性也不作担保。本网站有判定用户的行为是否符合本网站服务条款的要求和精神的保留权利，如果用户违背了服务条款的规定，本网站有中断对其提供网络服务的权利。本网站运用自己的操作系统通过国际互联网向用户提供丰富的网上资源，包括各种信息工具、网上论坛、个性化内容等。除非另有明确规定，增强或强化目前服务的任何新功能，包括新产品、栏目，均无条件地适用本服务条款。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">10、用户管理</h4>
            <div class="divider">
              用户单独承担发布内容的责任。用户对服务的使用是根据所有适用于本网站的国家法律、地方法律和国际法律标准的。
              用户必须遵循：
              <div class="divider">
                （1）使用信息服务不作非法用途。


              </div>
              <div class="divider">
                （2）不干扰或混乱网络服务。


              </div>
              <div class="divider">
                （3）遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。


              </div>
              <div class="divider">
                 (4) 禁止用户从事以下行为：
                <p class="indent">
                  1)上载、张贴、发送或传送任何非法、反动、淫秽、粗俗、猥亵的，胁迫、骚扰、中伤他人、诽谤、侵害他人隐私或诋毁他人名誉或商誉的，种族歧视、危害未成年人或其他不适当的信息或电子邮件，包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料（以下简称内容）。
                </p>
                <p class="indent">2) 未经本网站许可的广告行为。</p>
                <p class="indent">3) 冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关。</p>
                <p class="indent">4) 伪造标题或以其他方式操控识别资料，使人误认为该内容为本网站所传送。</p>

                <p class="indent">5) 上载、张贴、发送电子邮件或以其它方式传送无权传送的内容（例如内部资料、机密资料）。</p>

                <p class="indent">6) 上载、张贴、发送电子邮件或以其它方式传送侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利之内容。</p>

                <p class="indent">7) 在本网站提供的专供张贴广告的区域之外，上载、张贴、发送电子邮件或以其他方式传送广告函件、促销资料、“垃圾邮件”等。</p>

                <p class="indent">8) 上载、张贴、发送电子邮件或以其他方式传送有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料。</p>

                <p class="indent">9) 干扰或破坏本服务或与本服务相连的服务器和网络，或不遵守本服务协议之规定。</p>

                <p class="indent">10) 故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。</p>

                <p class="indent">11) 跟踪或以其他方式骚扰他人。</p>

                <p class="indent">12) 其它被本网站视为不适当的行为。</p>



              </div>

            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">11、保障</h4>
            <div class="divider">
              用户同意保障和维护本网站全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">12、结束服务</h4>

            <div class="divider">
              用户或本网站可随时根据实际情况中断一项或多项服务。本网站不需对任何个人或第三方负责而随时中断服务。用户若持有反对任何服务条款的建议或对后来的条款修改有异议，或对本网站服务不满，用户可以行使如下权利：
              <p class="indent">（1）不再使用本网站信息服务。</p>
              <p class="indent">（2）通知本网站停止对该用户的服务。
                结束用户服务后，用户使用本网站服务的权利马上中止。从那时起，用户没有权利，本网站也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。</p>

            </div>

          </div>
          <div class="divider">
            <h4 class="txt-title">13、通告</h4>
            <div class="divider">
              所有发给用户的通告都可通过重要页面的公告或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">14、信息内容的所有权</h4>
            <div class="divider">
              本网站定义的信息内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护，所以用户只能在本网站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">15、免责声明</h4>
            <div class="divider">
              对于本服务包含的或用户经由或从任何与本服务有关的途径所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本服务上的广告、展示而购买、取得的任何产品、信息或资料，本网站不负保证责任。用户自行承担担使用本服务的风险。
            </div>


          </div>
          <div class="divider">
            <h4 class="txt-title">16、法律</h4>
            <div class="divider">
              本网站信息服务条款的订立、解释、履行及效力均受中华人民共和国大陆地区法律管辖。如发生本网站服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户的约束力。
              如用户就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向中储京科供应链管理有限公司工商注册地法院提起诉讼。因诉讼产生的案件受理费、律师费、保全费、鉴定费、差旅费等一切费用均由败诉方承担”
              在任何情况下，用户不得利用本网站进行违反或可能违反国家法律和法规的言论或行为，否则，本网站可在任何时候不经任何事先通知终止向您提供服务。并且用户对自己的言论或行为负责。
            </div>


          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { title: "法律声明" },
        { title: "隐私声明" },
        { title: "使用条款" },
      ],
      selectName: "",
    };
  },
  watch: {
    $route(to, from) {
      // console.log(to);
      this.selectName = to.query.selectName;
      document.title = this.selectName + "_京科仓链";
      this.scrollToTop();
    },
  },
  created() {
    document.title = this.$route.query.selectName + "_京科仓链";
  },
  mounted() {
    this.scrollToTop();
    this.selectName = this.$route.query.selectName;
    document.getElementById("nav").classList.add("fixedNav");
  },
  destroyed() {
    document.getElementById("nav").classList.remove("fixedNav");
  },
  methods: {
    select(val) {
      this.selectName = val.title;
      this.$router.push({
        path: "/law",
        query: { selectName: this.selectName },
      });
    },
    scrollToTop() {
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: 120px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  color: #a0a2ae;
  background-color: rgba(52, 50, 50, 0.102);
}

.sidebar {
  width: 240px;
  height: auto;
  padding: 10px 0;
  background: #fff;
  --row-height: 40px;
  transition: all 0.3s;
}

.side-item {
  height: var(--row-height);
  border-left: 2px solid #fff;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  line-height: var(--row-height);
  transition: all 0.3s;
}

.side-item span {
  font-size: 17px;
}

.active {
  color: #00b079;
  border-left: 2px solid #00b079;
  transition: all 0.3s;
}

.left {
  background-color: #fff;
  width: 240px;
  height: 120px;
  padding: 10px 0;

  >.leftOne {
    color: #d0d1d6;
    font-size: 14px;
    width: 238px;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }

  .leftActive {
    border-left: 2px solid #036f4d;
    color: #036f4d;
  }
}

.right {
  background-color: #fff;
  margin-left: 20px;
  width: 920px;

  .tip {
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    color: #333;
    font-size: 16px;
    line-height: 60px;
    font-weight: 700;
  }

  .inner {
    padding: 20px;
    color: #666;
    font-size: 14px;
    line-height: 20px;

    .divider {
      color: #666;
      padding-top: 15px;

      p {
        color: #666;
        padding-top: 15px;
      }
    }
  }
}
</style>
